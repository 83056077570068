import debounce from "lodash/debounce";
import omit from "lodash/omit";
import React, { FC, useMemo } from "react";

import { FieldError } from "@src/components/atoms/FieldError";
import { Label } from "@src/components/atoms/Label";
import {
  FieldWrapper,
  StyledOption,
  StyledRequiredLabel,
  StyledSelect,
} from "@src/components/styles";
import { AnswerInput } from "@src/types";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";
import { MutlipleChoiceProps } from "../types";

const DropdownField: FC<MutlipleChoiceProps> = ({
  id,
  label,
  value,
  options,
  pinnedValueOptions,
  sort,
  isRequired,
  error,
  touched,
  setValue,
  saveAnswer,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  if (sort) {
    options.sort();
  }

  if (pinnedValueOptions) {
    options = options.filter((x) => !pinnedValueOptions.includes(x));
    options.unshift(...pinnedValueOptions);
  }

  const saveChange = useMemo(() => debounce(async (newAnswer: AnswerInput) => {
    const answerId = saveAnswer ? await saveAnswer(newAnswer) : "";

    setValue({
      ...newAnswer,
      answerId,
    });
  }, 1000, { trailing: true }), [saveAnswer, setValue]);

  const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAnswer: AnswerInput = omit(
      {
        ...value,
        value: e.target.value,
      },
      "__typename"
    );

    setValue(newAnswer);

    await saveChange(newAnswer);
  };

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <StyledSelect
        hasError={!!error && touched}
        name={id}
        data-testid={id}
        value={value?.value || ""}
        onChange={handleChange}
      >
        <StyledOption value="">{t("labels.selectAnOption")}</StyledOption>

        {options.map((option) => (
          <StyledOption key={option} value={option}>
            {googleTranslate(option)}
          </StyledOption>
        ))}
      </StyledSelect>
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { DropdownField };

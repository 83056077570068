import debounce from "lodash/debounce";
import omit from "lodash/omit";
import React, { ChangeEventHandler, FC, useMemo } from "react";

import { FieldError } from "@src/components/atoms/FieldError";
import { Label } from "@src/components/atoms/Label";
import {
  FieldWrapper,
  StyledOption,
  StyledRequiredLabel,
  StyledSelect,
} from "@src/components/styles";
import { AnswerInput, Field } from "@src/types";
import { deleteNestedAnswers } from "@src/utils/answers";
import { getFormInitialValues } from "@src/utils/formValues";
import { ConditionalValue, InitialValuesObject } from "@src/utils/formValuesTypes";
import { getPinnedValueOptions, getValueOptions } from "@src/utils/getters";
import { useGoogleTranslate } from "@src/utils/translation";
import { useTranslation } from "react-i18next";
import { FieldSelector } from "../FieldSelector";

type DropdownConditionalProps = {
  id: string;
  label: string;
  value: ConditionalValue;
  isRequired: boolean;
  field: Field;
  uploadUrl?: string;
  viewFileUrl?: string;
  error?: Record<string, string> | string;
  setValue: (value: ConditionalValue) => void;
  saveAnswer?: (answer: AnswerInput) => Promise<string>;
  deleteAnswer?: (answer: AnswerInput) => Promise<unknown>;
};

const DropdownConditionalField: FC<DropdownConditionalProps> = ({
  id,
  label,
  value,
  isRequired,
  field,
  uploadUrl,
  viewFileUrl,
  setValue,
  saveAnswer,
  deleteAnswer,
}) => {
  const { t } = useTranslation();
  const googleTranslate = useGoogleTranslate();

  let options = getValueOptions(field);
  const pinnedValueOptions = getPinnedValueOptions(field);

  if (field.question?.sortValueOptions) {
    options.sort();
  }

  if (pinnedValueOptions) {
    options = options.filter((x) => !pinnedValueOptions.includes(x));
    options.unshift(...pinnedValueOptions);
  }

  const selectedIndex = getValueOptions(field).findIndex((option) => option === value.answer.value);
  const selectedOptionFields =
    selectedIndex >= 0 && field.fields && field.fields[selectedIndex]
      ? field.fields[selectedIndex].fields || []
      : [];


  const saveChange = useMemo(() => debounce(async (newValue: InitialValuesObject & { answer: AnswerInput }) => {
    const answerId = saveAnswer ? await saveAnswer(newValue.answer) : "";

    setValue({
      ...newValue,
      answer: {
        ...newValue.answer,
        answerId,
      },
    });
  }, 1000, { trailing: true }), [saveAnswer, setValue]);


  const handleChange: ChangeEventHandler<HTMLSelectElement> = async ({
    target: { value: eventValue },
  }) => {
    const newAnswer: AnswerInput = omit({ ...value.answer, value: eventValue }, "__typename");

    if (eventValue !== value.answer.value) {
      if (!eventValue) {
        const allFields = field.fields
          ? field.fields.flatMap((fieldGroup) => (fieldGroup.fields ? fieldGroup.fields : []))
          : [];

        const clearedFields = getFormInitialValues({ fields: allFields });

        deleteNestedAnswers({ clearedFields, value, deleteAnswer });

        const newValue = {
          ...clearedFields,
          answer: newAnswer,
        };

        setValue(newValue);

        await saveChange(newValue);
      } else {
        const newValue = {
          ...value,
          answer: newAnswer,
        };

        setValue(newValue);

        await saveChange(newValue);
      }
    }
  };

  return (
    <FieldWrapper id={id}>
      {isRequired ? <StyledRequiredLabel /> : null}
      <Label>{label}</Label>

      <StyledSelect
        data-testid={id}
        value={value.answer.value || ""}
        name={field.id || ""}
        onChange={handleChange}
      >
        <StyledOption value="">{t("labels.selectAnOption")}</StyledOption>

        {options.map((option) => (
          <StyledOption key={option} value={option}>
            {googleTranslate(option)}
          </StyledOption>
        ))}
      </StyledSelect>

      {selectedOptionFields.map((field) => (
        <FieldSelector
          key={field.id}
          field={field}
          containerQuestionId={id}
          uploadUrl={uploadUrl}
          viewFileUrl={viewFileUrl}
          saveAnswer={saveAnswer}
          deleteAnswer={deleteAnswer}
        />
      ))}
      <FieldError id={id} name={id} />
    </FieldWrapper>
  );
};

export { DropdownConditionalField };
